import React from 'react';
import { useNavigate, useLocation, Link as LinkNav } from 'react-router-dom';
import { Link as ReactLink } from 'react-scroll';

import { Icon, Box, Flex, HStack, Image, Text } from '@chakra-ui/react';

import logo from '../../Assets/Png/logo.png';

import { UserContext } from '../../Context/UserContext';

import { HiHome } from 'react-icons/hi';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaShoppingCart } from 'react-icons/fa';

import PerfilPhoto from '../PerfilPhoto';
import { IoPodium } from 'react-icons/io5';
import { FaUser } from 'react-icons/fa6';

export function Header() {
  const { IsLogin } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  // cores do menu

  const NavLink = ({ children }) => (
    <ReactLink //tive que mudar para o link do react
      to={children.link}
      smooth
      offset={-100}
      duration={600}
      delay={100}
    >
      <Flex align="center" _hover={{ color: 'black' }} gap="1">
        <Icon as={children.icon} />
        <Text
          cursor="pointer"
          fontFamily="Ubuntu Mono"
          textTransform="uppercase"
          fontSize={{ md: '11', lg: '14' }}
        >
          {children.nome}
        </Text>
      </Flex>
    </ReactLink>
  );

  return (
    <>
      {!IsLogin && (
        <Flex
          px={4}
          bg={'#fcbf14'}
          color="black"
          position="fixed"
          w="100%"
          top={{ base: null, md: '0' }}
          align="center"
          bottom={{ base: '0', md: null }}
          left="0"
          zIndex="999"
          maxHeight={'60px'}
          justifyContent={{ base: 'center', md: 'center' }}
        >
          <Flex
            maxW="1200px"
            w="100%"
            h={16}
            alignItems={'center'}
            justifyContent={{ base: 'center', md: 'space-between' }}
            gap={10}
          >
            <HStack
              spacing={10}
              alignItems={'center'}
              display={{ base: 'none', md: 'flex' }}
            >
              <LinkNav to={'/'}>
                <Image src={logo} h="35" />
              </LinkNav>
            </HStack>

            <Flex alignItems={'center'} display={{ base: 'none', md: 'flex' }}>
              <HStack as={'nav'} spacing={4} mr="5" display={'flex'}>
                <LinkNav to={'/'}>
                  {' '}
                  <Text
                    as="b"
                    _hover={{ color: 'white' }}
                    color={location.pathname === '/' ? 'white' : 'black'}
                  >
                    Visão Geral
                  </Text>{' '}
                </LinkNav>

                <LinkNav to={'/Vendas'}>
                  {' '}
                  <Text
                    as="b"
                    _hover={{ color: 'white' }}
                    color={location.pathname === '/Vendas' ? 'white' : 'black'}
                  >
                    Vendas
                  </Text>{' '}
                </LinkNav>

                <LinkNav to={'/Cliente'}>
                  {' '}
                  <Text
                    as="b"
                    _hover={{ color: 'white' }}
                    color={location.pathname === '/Cliente' ? 'white' : 'black'}
                  >
                    Clientes
                  </Text>{' '}
                </LinkNav>

                <LinkNav to={'/Financeiro'}>
                  {' '}
                  <Text
                    as="b"
                    _hover={{ color: 'white' }}
                    color={
                      location.pathname === '/Financeiro' ? 'white' : 'black'
                    }
                  >
                    Financeiro
                  </Text>{' '}
                </LinkNav>

                <LinkNav to={'/Rankings'}>
                  {' '}
                  <Text
                    as="b"
                    _hover={{ color: 'white' }}
                    color={
                      location.pathname === '/Rankings' ? 'white' : 'black'
                    }
                  >
                    Rankings
                  </Text>{' '}
                </LinkNav>
              </HStack>

              <PerfilPhoto />
              {/* <ColorModeSwitcher /> */}
            </Flex>

            <Flex alignItems={'center'} display={{ base: 'flex', md: 'none' }}>
              <Flex justify="space-around" w="100vw" align="center">
                <Flex align="center">
                  <LinkNav to={'/'}>
                    <Flex direction="column" align="center">
                      <HiHome
                        size="25"
                        color={location.pathname === '/' ? 'white' : 'black'}
                      />{' '}
                      <Text
                        fontSize="11"
                        color={location.pathname === '/' ? 'white' : 'black'}
                      >
                        inicio
                      </Text>
                    </Flex>
                  </LinkNav>
                </Flex>

                <Flex align="center">
                  <LinkNav to={'/Vendas'}>
                    {' '}
                    <Flex direction="column" align="center">
                      <FaShoppingCart
                        size="25"
                        color={
                          location.pathname === '/Vendas' ? 'white' : 'black'
                        }
                      />{' '}
                      <Text
                        fontSize="11"
                        color={
                          location.pathname === '/Vendas' ? 'white' : 'black'
                        }
                      >
                        vendas
                      </Text>
                    </Flex>
                  </LinkNav>
                </Flex>

                <Flex align="center">
                  <LinkNav to={'/Cliente'}>
                    {' '}
                    <Flex direction="column" align="center">
                      <FaUser
                        size="25"
                        color={
                          location.pathname === '/Cliente' ? 'white' : 'black'
                        }
                      />{' '}
                      <Text
                        fontSize="11"
                        color={
                          location.pathname === '/Cliente' ? 'white' : 'black'
                        }
                      >
                        clientes
                      </Text>
                    </Flex>
                  </LinkNav>
                </Flex>

                <Flex align="center">
                  <LinkNav to={'/Financeiro'}>
                    {' '}
                    <Flex direction="column" align="center">
                      <RiMoneyDollarCircleFill
                        size="25"
                        color={
                          location.pathname === '/Financeiro'
                            ? 'white'
                            : 'black'
                        }
                      />{' '}
                      <Text
                        fontSize="11"
                        color={
                          location.pathname === '/Financeiro'
                            ? 'white'
                            : 'black'
                        }
                      >
                        financeiro
                      </Text>
                    </Flex>
                  </LinkNav>
                </Flex>

                <Flex align="center">
                  <LinkNav to={'/Rankings'}>
                    {' '}
                    <Flex direction="column" align="center">
                      <IoPodium
                        size="25"
                        color={
                          location.pathname === '/Rankings' ? 'white' : 'black'
                        }
                      />{' '}
                      <Text
                        fontSize="11"
                        color={
                          location.pathname === '/Rankings' ? 'white' : 'black'
                        }
                      >
                        rankings
                      </Text>
                    </Flex>
                  </LinkNav>
                </Flex>
                <PerfilPhoto />
                {/* <Box><LinkNav to={'/Perfil'} > <FaUser size='25' color={location.pathname === '/Perfil' ? 'white' : 'black'} /> </LinkNav></Box> */}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default Header;
