import React from 'react';

import { UserContext } from '../../Context/UserContext';
import { Link } from 'react-router-dom';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Text,
  PopoverArrow,
  Box,
  Flex,
  Divider,
  useColorModeValue,
  Center,
} from '@chakra-ui/react';

import { FaUser } from 'react-icons/fa';
import { CgSync } from 'react-icons/cg';

export function PerfilPhoto() {
  const { userdata, LogoutSys } = React.useContext(UserContext);
  const [userdados, setuserdados] = React.useState(
    userdata?.lenght > 0
      ? userdata
      : JSON.parse(localStorage.getItem('userdataSI'))
  );
  const [idemp, setidemp] = React.useState(
    JSON.parse(localStorage.getItem('userempidSI'))
  );

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sd"
          height="30px"
          width="32px"
          colorScheme="blackAlpha"
          borderRadius="full"
          fontSize={'15'}
        >
          <FaUser />
        </Button>
      </PopoverTrigger>
      <PopoverContent maxW="180px">
        <PopoverArrow />
        <PopoverBody>
          <Flex align="center" direction="column">
            <Box mt="2">
              <Box borderRadius="full" bg="#FFA800" p="2">
                <Center>
                  <FaUser size="20" color="white" />
                </Center>
              </Box>
            </Box>
            <Text as="b" mt="2" color={useColorModeValue('black', 'white')}>
              {userdados && userdados?.usuario?.nome_usu}
            </Text>

            <Text as="b" mt="2" color={useColorModeValue('black', 'white')}>
              {userdados &&
                userdados?.usuario?.empresas[Number(idemp - 1)]?.fantasia_emp}
            </Text>
            {/* {console.log(userdados?.usuario?.empresas[Number(idemp -1)])} */}

            <Divider m="2" my="4" />

            <Flex direction="column" gap="2">
              <Link to="/SelecaoEmpresa">
                <Flex align="center" gap="1">
                  <CgSync color="#ed8936" />
                  <Text color="orange.400" fontWeight="600">
                    trocar Empresa
                  </Text>
                </Flex>
              </Link>

              {/* <Link to='/AlteraSenha'>
                <Flex align='center' gap='1'>
                  <CgSync color='red' />
                  <Text color='red' fontWeight='600'>Alterar Senha</Text>
                </Flex>
              </Link> */}

              <Divider mx="2" my="1" />

              <Button
                colorScheme="red"
                size="sm"
                variant="outline"
                onClick={LogoutSys}
              >
                Sair
              </Button>
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default PerfilPhoto;
