import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'rn-crypto-js';

import { useDisclosure, useToast } from '@chakra-ui/react';

export const UserContext = React.createContext();

export const UserDataContext = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [login, setLogin] = React.useState(false); //para validar se o usuario ja fez o login
  const [userdata, setuserdata] = React.useState({});
  const [userempid, setuserempid] = React.useState({});
  const [loading, setloading] = React.useState(false);
  const [IsLogin, setIsLogin] = React.useState(true); // para esconder o header no login
  const [numIPContext, setnumIPContext] = React.useState('');
  const [numportaContext, setnumPortaContext] = React.useState('');

  React.useEffect(() => {
    const clienteData = JSON.parse(localStorage.getItem('@SIDASH-USER'));
    // const clienteData = JSON.parse(localStorage.getItem('@SIDASH-USER'));
    // const dadosAcesso = JSON.parse(localStorage.getItem('@SIDASH-IP'));

    if (clienteData) {
      const decryptedData = CryptoJS.AES.decrypt(
        clienteData?.hash,
        'SI'
      ).toString(CryptoJS.enc.Utf8);

      realizaLogin(
        clienteData?.nome_usu,
        decryptedData,
        clienteData?.ip,
        clienteData?.porta,
        0
      );

      console.log('efeito');
    } else {
      navigate('login');
    }
  }, []);

  const realizaLogin = (name, senha, ip, porta, redirect) => {
    axios
      .get(`http://${ip}:${porta}/usuarios?usuario=${name}&senha=${senha}`)
      .then(item => {
        // console.log(item);
        if (item?.data?.status === 1) {
          toast({
            title: 'FALHA AO REALIZAR LOGIN',
            status: 'error',
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
          });
        } else {
          const user = item?.data?.usuario;

          setuserdata(user);

          const ciphertext = CryptoJS.AES.encrypt(senha, 'SI').toString();

          window.localStorage.setItem(
            '@SIDASH-USER',
            JSON.stringify({ ...user, hash: ciphertext, ip: ip, porta: porta })
          );

          setLogin(true);
          if (redirect === 1) {
            toast({
              title: `LOGIN REALIZADO COM SUCESSO, ${name}`,
              status: 'success',
              variant: 'subtle',
              duration: 3000,
              isClosable: true,
            });
            navigate('/SelecaoEmpresa');
          }
        }
      })
      .catch(error => {
        console.log(error);
        navigate('login');
        if (redirect === 1) {
          toast({
            title: `FALHA AO REALIZAR LOGIN, ${name}`,
            status: 'error',
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
          });
        }
      });
  };

  function LogoutSys() {
    // alterar para funções validas
    setuserdata(null);
    setuserempid(null);
    localStorage.removeItem('userdataSI');
    localStorage.removeItem('userempidSI');
    localStorage.removeItem('tokenSI');
    localStorage.removeItem('emailSI');
    navigate('/login');
  }

  function formataValor(valor) {
    return valor?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function inverteData(data) {
    const aux = data.split('-');
    return `${aux[2]}/${aux[1]}/${aux[0]}`;
  }

  return (
    <UserContext.Provider
      value={{
        inverteData,
        numportaContext,
        setnumPortaContext,
        numIPContext,
        setnumIPContext,
        realizaLogin,
        userempid,
        setuserempid,
        formataValor,
        login,
        setLogin,
        loading,
        userdata,
        setuserdata,
        setIsLogin,
        IsLogin,
        LogoutSys,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const toastErroLogin = {
  title: 'Falha no login.',
  description: 'Tente novamente mais tarde.',
  status: 'error',
  duration: 4000,
  isClosable: true,
};

const toastErroDados = {
  title: 'Dados Invalidos.',
  description: 'è necessario preencher todos os campos.',
  status: 'error',
  duration: 4000,
  isClosable: true,
};

const toastClienteInativo = {
  title: 'Cliente Inativo.',
  description: 'Entre em contato com os administradores do sistema.',
  status: 'error',
  duration: 4000,
  isClosable: true,
};

const toastLogout = {
  title: 'Logout Realizado.',
  description: 'Ate a proxima, ficamos a disposição',
  status: 'error',
  duration: 3000,
  isClosable: true,
};

const toastSucessoLogin = {
  title: 'Login Realizado.',
  description: 'Tudo certo, vamos ao trabalho.',
  status: 'success',
  duration: 3000,
  isClosable: true,
};
